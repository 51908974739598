import {
  FACEBOOK,
  FACEBOOK_URL,
  LINKEDIN,
  LINKEDIN_URL,
  TWITTER,
  TWITTER_URL,
} from '../constants/sharing';

/**
 * Calculate sharing url for given Social platform
 * using a type and a url
 *
 * @param sharingURL Content's page url
 * @param shareText  Sharing text
 * @param title      Content's title
 * @param type       Platform to share to
 * @param shareSite  Site name
 */
export const getShareUrl = (
  sharingURL: string,
  shareText: string,
  title: string,
  type: string,
  shareSite: string,
): string => {
  let shareTracking = '';
  let shareUrl = '';

  switch (type) {
    case FACEBOOK:
      shareTracking = `${sharingURL}&share=facebook`;
      shareUrl = `${FACEBOOK_URL}?u=${shareTracking}`;
      break;
    case TWITTER: {
      // Build tweet text if t & title are available
      // TODO remove else statement once I18NProvider is available as sharedComponent
      let text = '';
      if (title) {
        text = `${encodeURIComponent(shareText)}&via=${shareSite}`;
      }

      shareTracking = `&url=${sharingURL}&share=twitter`;
      shareUrl = `${TWITTER_URL}?text=${text}${shareTracking}`;
      break;
    }
    case LINKEDIN: {
      shareTracking = `/?url=${sharingURL}&share=linkedin`;
      shareUrl = `${LINKEDIN_URL}${shareTracking}`;
      break;
    }
    default:
      break;
  }

  return shareUrl;
};

export const getShareWindowOptions = (
  width: number,
  height: number,
): string => {
  const left =
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2;
  const top =
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2;

  const options = {
    height,
    width,
    left,
    top,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  };

  return Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join(', ');
};
