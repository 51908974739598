import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import { LINKEDIN } from '../../constants/sharing';
import { getShareUrl, getShareWindowOptions } from '../../helpers/sharing';
import styles from './ShareButtonWrapper.module.css';

export type ShareButtonWrapperProps = {
  type: string;
  shareContent?: (type: string) => void;
  children?: ReactNode;
  shareSite?: string;
  shareText?: string;
  sharingURL: string;
  title?: string;
  ariaLabel?: string;
};

export const ShareButtonWrapper = ({
  children,
  shareContent,
  shareSite = '',
  shareText = '',
  sharingURL,
  title = '',
  type,
  ariaLabel = '',
}: ShareButtonWrapperProps): JSX.Element => {
  const openShareWindow = () => {
    const shareUrl = getShareUrl(sharingURL, shareText, title, type, shareSite);

    const shareWindowOptions =
      type === LINKEDIN
        ? getShareWindowOptions(660, 650)
        : getShareWindowOptions(560, 510);

    shareContent?.(type);

    !window.open(shareUrl, type, shareWindowOptions);
  };

  return (
    <button
      type="button"
      className={classNames(styles.ShareButtonWrapper, type)}
      onClick={openShareWindow}
      title={title}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};
